<template>
  <div class="modal fade" :id="'createSiteModal' + id" tabindex="-1" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create Site</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-2">
              <p>Fields marked <span class="text-danger">*</span> are required</p>
            </div>
            <div class="col-md-12 mb-2">
              <label for="siteName" class="form-label">Name <span class="text-danger">*</span></label>
              <input autocomplete="off" v-on:keyup="findCloseSites" type="text" class="form-control" id="siteName" v-model="site.name">
            </div>
            <div v-if="potentialMatches.length > 0" class="col-md-12 mb-2">
              <span class="text-warning cursor-pointer" @click="openExistingSites"><i class='bx bxs-flag-alt'></i> {{potentialMatches.length}} existing site{{potentialMatches.length > 1 ? 's are' : ' is'}} similar to this!</span>
            </div>
            <div class="col-md-12 mb-2">
              <label for="siteClient" class="form-label">Client <span class="text-danger">*</span></label>
              <Multiselect v-model="site.client_id" id="siteClient" valueProp="id" label="name" :searchable="true" :options="clients"></Multiselect>
            </div>
            <div class="col-md-12 mb-2">
              <label for="siteStreet" class="form-label">Street</label>
              <input type="text" class="form-control" id="siteStreet" v-model="site.street">
            </div>
            <div class="col-md-12 mb-2">
              <label for="siteTown" class="form-label">Town</label>
              <input type="text" class="form-control" id="siteTown" v-model="site.town">
            </div>
            <div class="col-md-12 mb-2">
              <label for="sitecounty" class="form-label">County</label>
              <input type="text" class="form-control" id="sitecounty" v-model="site.county">
            </div>
            <div class="col-md-12 mb-2">
              <label for="sitePostcode" class="form-label">Postcode</label>
              <input type="text" class="form-control" id="sitePostcode" v-model="site.postcode">
            </div>
            <div class="col-md-12 mb-2">
              <label for="siteTelephone" class="form-label">Telephone</label>
              <input type="tel" class="form-control" id="siteTelephone" v-model="site.telephone">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id='"closeCreateSiteModal" + id'>Close</button>
          <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createSite" text="Save changes"></submit-button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" :id="'existingSitesModal' + id" tabindex="-1" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Existing Sites</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">

            <table class="table">
              <thead>
              <tr>
                <th>Client</th>
                <th>Name</th>
                <th>Street</th>
                <th>Postcode</th>
                <th style="min-width: 100px;"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="site in potentialMatches" :key="'esite-' + site.id">
                <td>{{site.client_name}}</td>
                <td>{{site.name}}</td>
                <td>{{site.street || "-"}}</td>
                <td>{{site.postcode || "-"}}</td>
                <td style="min-width: 100px;"><button class="btn btn-sm btn-outline-primary" @click="goTo(site)">Go To</button></td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeExistingSites" :id='"closeExistingSitesModal" + id'>Close</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* global $ */
import axios from "axios";
import Multiselect from '@vueform/multiselect';
import SubmitButton from "../../../components/SubmitButton";

export default {
  name: "NewSiteForm",
  emits: ['created'],
  components: {
    Multiselect,
    SubmitButton
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data(){
    return {
      site: {
        name: '',
        street: null,
        town: null,
        county: null,
        postcode: null,
        telephone: null,
        client_id: null
      },
      creating: false,
      clients: [],
      potentialMatches: []
    }
  },
  mounted(){
    this.loadClients();
  },
  methods: {
    show(){
      console.log("Showing new site form");
      $('#createSiteModal' + this.id).modal('toggle');
    },
    loadClients(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/list`)
          .then(response => {
            this.clients = response.data.clients;
            this.clients.forEach((client) => {
              client.text = client.name;
            })
            this.site.client_id = this.clients[0].id;
          })
          .catch(error => {
            this.$error("Failed to load clients", error);
          })
    },
    findCloseSites(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites?search=${this.site.name}`)
      .then(response => {
        this.potentialMatches = response.data.sites;
      })
      .catch(error => {
        this.$error("Failed to find potential matches", error);
      })
    },
    openExistingSites(){
      $('#closeCreateSiteModal').click();
      $('#existingSitesModal').modal('toggle');
    },
    closeExistingSites(){
      $('#existingSitesModal').modal('toggle');
      this.show();
    },
    goTo(site){
      $('#existingSitesModal').modal('toggle');
      this.$router.push({name: 'admin.sites.view', params: {id: site.id}})
    },
    createSite(){
      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/sites`, this.site)
          .then(response => {
            $('#closeCreateSiteModal').click();
            this.$success("Created new site");
            this.creating = false;
            this.site = {
              name: '',
              street: null,
              town: null,
              county: null,
              postcode: null,
              telephone: null,
              client_id: null
            }
            this.$emit('created', response.data.id);
          })
          .catch(error => {
            this.$error("Failed to create new site.", error);
            this.creating = false;
          })
    },
  }
}
</script>

<style scoped>

</style>

<style src="@vueform/multiselect/themes/default.css"></style>